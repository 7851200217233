<template>
  <svg
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
    class="dropdown-arrow-icon"
    :style="{ transform: 'rotate(' + transform + 'deg)' }"
  >
    <path
      d="M15.88 9.29006L12 13.1701L8.11998 9.29006C7.72998 8.90006 7.09998 8.90006 6.70998 9.29006C6.31998 9.68006 6.31998 10.3101 6.70998 10.7001L11.3 15.2901C11.69 15.6801 12.32 15.6801 12.71 15.2901L17.3 10.7001C17.69 10.3101 17.69 9.68006 17.3 9.29006C16.91 8.91006 16.27 8.90006 15.88 9.29006Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DropdownArrowIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    transform: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
