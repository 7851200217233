<template>
  <div class="w-100">
    <div class="row-start mb-7">
      <p class="f-18-black f-sbold">{{ title }}</p>
    </div>
    <div class="card">
      <p class="column-start mb-40">
        <span class="f-18-accent-green f-sbold">
          {{ statisticData.last_month }}
          <arrow-icon class="ml-2 i-accent-green" size="18" transform="0" />
        </span>
        <span class="f-14-darkgrey mt-1">Over the past month</span>
      </p>
      <p class="column-start">
        <span class="f-18-accent-green f-sbold">
          {{ statisticData.last_year }}
          <!-- Apply i-red and f-18-red class for negative data -->
          <arrow-icon class="ml-2 i-accent-green" size="18" transform="0" />
        </span>
        <span class="f-14-darkgrey mt-1">Over the last year</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ArrowIcon } from "@/icons";

export default {
  name: "UpOrDownIndicators",
  components: {
    ArrowIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    statisticData: {
      type: Object,
      required: true,
    },
  },
};
</script>
