import { ifAuthenticated } from "@/helpers/routerHelpers";

const residentsRoutes = [
  {
    path: "/residents",
    name: "ResidentsListPage",
    component: () =>
      import(
        /*webpackChunkName: "ResidentsListPage" */ "@/views/residents/ResidentsListPage.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/residents/:entityId",
    name: "SingleResident",
    props: true,
    component: () =>
      import(
        /*webpackChunkName: "SingleResident" */ "@/views/residents/SingleResident.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/residents/:entityId/inventory-list/:id",
    name: "InventoryList",
    props: true,
    component: () =>
      import(
        /*webpackChunkName: "InventoryList" */ "@/views/residents/InventoryList.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
];

export default residentsRoutes;
