<template>
  <div class="w-100 review-section">
    <div class="row-between mt-40 mb-7">
      <p class="lg-6 f-18-black f-sbold">Reviews</p>
      <div
        v-if="reviewsArray.length"
        class="lg-1 f-14-darkgrey row-baseline row-end pointer"
      >
        <context-menu
          unicId="filter-menu"
          width="170"
          iconTriger="filter-icon"
          contextControlTitle="Filter"
          ref="filter"
        >
          <p
            class="f-14-black row-baseline mb-7"
            @click="filterReviews(FILTER_BY.RATE)"
          >
            <star-icon class="i-black mr-4" />
            Top Rating
          </p>
          <p
            class="f-14-black row-baseline mb-7"
            @click="filterReviews(FILTER_BY.RECENT)"
          >
            <clock-icon class="i-black mr-4" size="14" />
            Recent Reviews
          </p>
          <p
            class="f-14-black row-baseline"
            @click="filterReviews(FILTER_BY.ALL)"
          >
            <checklist-icon class="i-black mr-4" size="14" />
            All
          </p>
        </context-menu>
      </div>
    </div>
    <template v-if="reviewsArray.length">
      <div
        v-for="review in reviewsArray"
        :key="review.id"
        class="row-start mb-20"
      >
        <div class="review-avatar">{{ getNameAbbr(review) }}</div>
        <div class="column-start pt-1">
          <div class="column-start">
            <p class="mb-4 f-16-black f-sbold">{{ review.user.fullname }}</p>
            <div class="row-baseline">
              <box-icon class="i-green" v-for="i in review.rate" :key="i" />
              <span class="ml-4 f-16-darkgrey">
                {{ reviewCreatedAgo(review) }}
              </span>
            </div>
          </div>
          <p class="f-16-black mt-6">
            {{ review?.description ? review?.description : "" }}
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="p-4 row-center">
        <p class="f-20-darkgrey">Reviews not found</p>
      </div>
    </template>
  </div>
</template>

<script>
import { BoxIcon, StarIcon, ClockIcon, ChecklistIcon } from "@/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default {
  name: "CompanyReview",
  components: {
    BoxIcon,
    StarIcon,
    ClockIcon,
    ChecklistIcon,
  },
  props: {
    reviews: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      FILTER_BY: {
        ALL: "all",
        RATE: "rate",
        RECENT: "recent",
      },
      filterBy: "",
    };
  },
  computed: {
    reviewsArray() {
      let sortedArray = JSON.parse(JSON.stringify(this.reviews));

      switch (this.filterBy) {
        case this.FILTER_BY.ALL: {
          return this.reviews;
        }
        case this.FILTER_BY.RATE:
          {
            sortedArray = sortedArray.sort((a, b) => {
              return b.rate - a.rate;
            });
          }
          break;
        case this.FILTER_BY.RECENT:
          {
            sortedArray = sortedArray.sort((a, b) => {
              return (
                dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
              );
            });
          }
          break;
      }

      return sortedArray;
    },
  },
  methods: {
    reviewCreatedAgo({ created_at }) {
      return dayjs(created_at).fromNow();
    },
    filterReviews(filterBy) {
      this.filterBy = filterBy;
      this.$refs.filter.toggleOpenContextMenu();
    },
    getNameAbbr({ user }) {
      if (!user?.fullname) {
        return "";
      }

      const nameArray = user.fullname.split(" ");
      let result = "";
      nameArray.forEach((el) => {
        result += " " + el.substr(0, 1).toUpperCase();
      });

      return result;
    },
  },
  created() {
    this.filterBy = this.FILTER_BY.ALL;
  },
};
</script>
