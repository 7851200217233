<template>
  <svg viewBox="0 0 34 34" :width="size" :height="size" class="truck-icon">
    <path
      d="M18.4167 20.8955H2.83333C2.25249 20.8955 1.77083 20.4138 1.77083 19.833V8.49967C1.77083 4.78801 4.78833 1.77051 8.49999 1.77051H21.25C21.8308 1.77051 22.3125 2.25217 22.3125 2.83301V16.9997C22.3125 19.153 20.57 20.8955 18.4167 20.8955ZM3.89583 18.7705H18.4167C19.3942 18.7705 20.1875 17.9772 20.1875 16.9997V3.89551H8.49999C5.96416 3.89551 3.89583 5.96384 3.89583 8.49967V18.7705Z"
    />
    <path
      d="M26.9167 29.3955H25.5C24.9192 29.3955 24.4375 28.9138 24.4375 28.333C24.4375 27.3555 23.6442 26.5622 22.6667 26.5622C21.6892 26.5622 20.8958 27.3555 20.8958 28.333C20.8958 28.9138 20.4142 29.3955 19.8333 29.3955H14.1667C13.5858 29.3955 13.1042 28.9138 13.1042 28.333C13.1042 27.3555 12.3108 26.5622 11.3333 26.5622C10.3558 26.5622 9.56249 27.3555 9.56249 28.333C9.56249 28.9138 9.08083 29.3955 8.49999 29.3955H7.08333C4.15083 29.3955 1.77083 27.0155 1.77083 24.083V19.833C1.77083 19.2522 2.25249 18.7705 2.83333 18.7705H18.4167C19.3942 18.7705 20.1875 17.9772 20.1875 16.9997V7.08301C20.1875 6.50217 20.6692 6.02051 21.25 6.02051H23.8567C25.2592 6.02051 26.5483 6.77136 27.2425 7.9897L29.665 12.2255C29.8492 12.5513 29.8492 12.9622 29.665 13.288C29.4808 13.6138 29.1267 13.8122 28.7442 13.8122H26.9167C26.7183 13.8122 26.5625 13.968 26.5625 14.1663V18.4163C26.5625 18.6147 26.7183 18.7705 26.9167 18.7705H31.1667C31.7475 18.7705 32.2292 19.2522 32.2292 19.833V24.083C32.2292 27.0155 29.8492 29.3955 26.9167 29.3955ZM26.4208 27.2705H26.9167C28.6733 27.2705 30.1042 25.8397 30.1042 24.083V20.8955H26.9167C25.5567 20.8955 24.4375 19.7763 24.4375 18.4163V14.1663C24.4375 12.8063 25.5425 11.6872 26.9167 11.6872L25.4008 9.03801C25.0891 8.48551 24.4942 8.14551 23.8567 8.14551H22.3125V16.9997C22.3125 19.153 20.57 20.8955 18.4167 20.8955H3.89583V24.083C3.89583 25.8397 5.32666 27.2705 7.08333 27.2705H7.57917C8.04667 25.6413 9.54833 24.4372 11.3333 24.4372C13.1183 24.4372 14.62 25.6413 15.0875 27.2705H18.9266C19.3941 25.6413 20.8958 24.4372 22.6808 24.4372C24.4658 24.4372 25.9533 25.6413 26.4208 27.2705Z"
    />
    <path
      d="M11.3333 32.2292C9.18 32.2292 7.4375 30.4867 7.4375 28.3333C7.4375 26.18 9.18 24.4375 11.3333 24.4375C13.4867 24.4375 15.2292 26.18 15.2292 28.3333C15.2292 30.4867 13.4867 32.2292 11.3333 32.2292ZM11.3333 26.5625C10.3558 26.5625 9.5625 27.3558 9.5625 28.3333C9.5625 29.3108 10.3558 30.1042 11.3333 30.1042C12.3108 30.1042 13.1042 29.3108 13.1042 28.3333C13.1042 27.3558 12.3108 26.5625 11.3333 26.5625Z"
    />
    <path
      d="M22.6667 32.2292C20.5133 32.2292 18.7708 30.4867 18.7708 28.3333C18.7708 26.18 20.5133 24.4375 22.6667 24.4375C24.82 24.4375 26.5625 26.18 26.5625 28.3333C26.5625 30.4867 24.82 32.2292 22.6667 32.2292ZM22.6667 26.5625C21.6892 26.5625 20.8958 27.3558 20.8958 28.3333C20.8958 29.3108 21.6892 30.1042 22.6667 30.1042C23.6442 30.1042 24.4375 29.3108 24.4375 28.3333C24.4375 27.3558 23.6442 26.5625 22.6667 26.5625Z"
    />
    <path
      d="M31.1667 20.8958H26.9167C25.5567 20.8958 24.4375 19.7767 24.4375 18.4167V14.1667C24.4375 12.8067 25.5567 11.6875 26.9167 11.6875H28.7442C29.1267 11.6875 29.4808 11.8858 29.665 12.2258L32.0875 16.4758C32.1725 16.6317 32.2292 16.8158 32.2292 17V19.8333C32.2292 20.4142 31.7475 20.8958 31.1667 20.8958ZM26.9167 13.8125C26.7183 13.8125 26.5625 13.9683 26.5625 14.1667V18.4167C26.5625 18.615 26.7183 18.7708 26.9167 18.7708H30.1042V17.2833L28.1208 13.8125H26.9167Z"
    />
  </svg>
</template>

<script>
export default {
  name: "TruckIcon",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
