<template>
  <svg viewBox="0 0 40 40" :width="size" :height="size" class="user-icon">
    <path
      d="M20.266 19.3666C20.216 19.3666 20.1827 19.3666 20.1327 19.3666C20.0494 19.3499 19.9327 19.3499 19.8327 19.3666C14.9994 19.2166 11.3494 15.4166 11.3494 10.7333C11.3494 5.96659 15.2327 2.08325 19.9994 2.08325C24.766 2.08325 28.6494 5.96659 28.6494 10.7333C28.6327 15.4166 24.966 19.2166 20.316 19.3666C20.2994 19.3666 20.2827 19.3666 20.266 19.3666ZM19.9994 4.58325C16.616 4.58325 13.8494 7.34992 13.8494 10.7333C13.8494 14.0666 16.4494 16.7499 19.766 16.8666C19.8494 16.8499 20.0827 16.8499 20.2994 16.8666C23.566 16.7166 26.1327 14.0333 26.1494 10.7333C26.1494 7.34992 23.3827 4.58325 19.9994 4.58325Z"
    />
    <path
      d="M20.2827 37.5834C17.016 37.5834 13.7327 36.7501 11.2493 35.0834C8.93268 33.5501 7.66602 31.4501 7.66602 29.1667C7.66602 26.8834 8.93268 24.7667 11.2493 23.2167C16.2493 19.9001 24.3493 19.9001 29.316 23.2167C31.616 24.7501 32.8993 26.8501 32.8993 29.1334C32.8993 31.4167 31.6327 33.5334 29.316 35.0834C26.816 36.7501 23.5493 37.5834 20.2827 37.5834ZM12.6327 25.3167C11.0327 26.3834 10.166 27.7501 10.166 29.1834C10.166 30.6001 11.0493 31.9667 12.6327 33.0167C16.7827 35.8001 23.7827 35.8001 27.9327 33.0167C29.5327 31.9501 30.3993 30.5834 30.3993 29.1501C30.3993 27.7334 29.516 26.3667 27.9327 25.3167C23.7827 22.5501 16.7827 22.5501 12.6327 25.3167Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIcon",
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
