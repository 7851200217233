<template>
  <div class="auth-layout">
    <slot />
  </div>
</template>

<script>
import {} from "@/components";

export default {
  name: "AuthLayout",
  components: {},
};
</script>
