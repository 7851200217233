<template>
  <svg viewBox="0 0 14 14" :width="size" :height="size" class="checklist-icon">
    <path
      d="M12.2501 11.8125H6.41675C6.17758 11.8125 5.97925 11.6142 5.97925 11.375C5.97925 11.1358 6.17758 10.9375 6.41675 10.9375H12.2501C12.4892 10.9375 12.6876 11.1358 12.6876 11.375C12.6876 11.6142 12.4892 11.8125 12.2501 11.8125Z"
    />
    <path
      d="M12.2501 7.72852H6.41675C6.17758 7.72852 5.97925 7.53018 5.97925 7.29102C5.97925 7.05185 6.17758 6.85352 6.41675 6.85352H12.2501C12.4892 6.85352 12.6876 7.05185 12.6876 7.29102C12.6876 7.53018 12.4892 7.72852 12.2501 7.72852Z"
    />
    <path
      d="M12.2501 3.64648H6.41675C6.17758 3.64648 5.97925 3.44815 5.97925 3.20898C5.97925 2.96982 6.17758 2.77148 6.41675 2.77148H12.2501C12.4892 2.77148 12.6876 2.96982 12.6876 3.20898C12.6876 3.44815 12.4892 3.64648 12.2501 3.64648Z"
    />
    <path
      d="M2.33334 4.22901C2.22251 4.22901 2.11167 4.18818 2.02417 4.10068L1.44084 3.51734C1.27167 3.34818 1.27167 3.06818 1.44084 2.89901C1.61001 2.72984 1.89001 2.72984 2.05917 2.89901L2.33334 3.17318L3.77417 1.73234C3.94334 1.56318 4.22334 1.56318 4.39251 1.73234C4.56167 1.90151 4.56167 2.18151 4.39251 2.35068L2.64251 4.10068C2.55501 4.18818 2.44417 4.22901 2.33334 4.22901Z"
    />
    <path
      d="M2.33334 8.31299C2.22251 8.31299 2.11167 8.27216 2.02417 8.18466L1.44084 7.60133C1.27167 7.43216 1.27167 7.15216 1.44084 6.98299C1.61001 6.81383 1.89001 6.81383 2.05917 6.98299L2.33334 7.25716L3.77417 5.81633C3.94334 5.64716 4.22334 5.64716 4.39251 5.81633C4.56167 5.98549 4.56167 6.26549 4.39251 6.43466L2.64251 8.18466C2.55501 8.27216 2.44417 8.31299 2.33334 8.31299Z"
    />
    <path
      d="M2.33334 12.395C2.22251 12.395 2.11167 12.3542 2.02417 12.2667L1.44084 11.6834C1.27167 11.5142 1.27167 11.2342 1.44084 11.065C1.61001 10.8959 1.89001 10.8959 2.05917 11.065L2.33334 11.3392L3.77417 9.89836C3.94334 9.72919 4.22334 9.72919 4.39251 9.89836C4.56167 10.0675 4.56167 10.3475 4.39251 10.5167L2.64251 12.2667C2.55501 12.3542 2.44417 12.395 2.33334 12.395Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ChecklistIcon",
  props: {
    size: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>
