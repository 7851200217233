<template>
  <svg viewBox="0 0 14 14" :width="size" :height="size" class="star-icon">
    <path
      d="M10.3016 13.2237C9.99241 13.2237 9.59574 13.1245 9.09991 12.8329L7.35574 11.8004C7.17491 11.6954 6.82491 11.6954 6.64991 11.8004L4.89991 12.8329C3.86741 13.4454 3.26074 13.2004 2.98657 13.002C2.71824 12.8037 2.29824 12.2962 2.57241 11.1295L2.98657 9.3387C3.03324 9.15203 2.93991 8.8312 2.79991 8.6912L1.35324 7.24453C0.629906 6.5212 0.688239 5.90286 0.787406 5.59953C0.886573 5.2962 1.20157 4.75953 2.20491 4.59036L4.06574 4.2812C4.24074 4.25203 4.49157 4.06536 4.56741 3.90786L5.59991 1.8487C6.06657 0.909531 6.67907 0.769531 6.99991 0.769531C7.32074 0.769531 7.93324 0.909531 8.39991 1.8487L9.42657 3.90203C9.50824 4.05953 9.75907 4.2462 9.93407 4.27536L11.7949 4.58453C12.8041 4.7537 13.1191 5.29036 13.2124 5.5937C13.3057 5.89703 13.3641 6.51536 12.6466 7.2387L11.1999 8.6912C11.0599 8.8312 10.9724 9.1462 11.0132 9.3387L11.4274 11.1295C11.6957 12.2962 11.2816 12.8037 11.0132 13.002C10.8674 13.107 10.6341 13.2237 10.3016 13.2237ZM6.99991 10.8437C7.28574 10.8437 7.57157 10.9137 7.79907 11.0479L9.54324 12.0804C10.0507 12.3837 10.3716 12.3837 10.4941 12.2962C10.6166 12.2087 10.7041 11.8995 10.5757 11.3279L10.1616 9.53703C10.0507 9.05287 10.2316 8.4287 10.5816 8.07286L12.0282 6.6262C12.3141 6.34036 12.4424 6.06036 12.3841 5.86786C12.3199 5.67536 12.0516 5.51786 11.6549 5.4537L9.79407 5.14453C9.34491 5.0687 8.85491 4.70703 8.65074 4.2987L7.62407 2.24536C7.43741 1.87203 7.20407 1.65036 6.99991 1.65036C6.79574 1.65036 6.56241 1.87203 6.38157 2.24536L5.34907 4.2987C5.14491 4.70703 4.65491 5.0687 4.20574 5.14453L2.35074 5.4537C1.95407 5.51786 1.68574 5.67536 1.62157 5.86786C1.55741 6.06036 1.69157 6.3462 1.97741 6.6262L3.42407 8.07286C3.77407 8.42286 3.95491 9.05287 3.84407 9.53703L3.42991 11.3279C3.29574 11.9054 3.38907 12.2087 3.51157 12.2962C3.63407 12.3837 3.94907 12.3779 4.46241 12.0804L6.20657 11.0479C6.42824 10.9137 6.71407 10.8437 6.99991 10.8437Z"
    />
  </svg>
</template>

<script>
export default {
  name: "StarIcon",
  props: {
    size: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>
