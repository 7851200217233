<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="home-icon-colored"
  >
    <path
      d="M28.3901 9.66205L20.2301 3.95288C18.0059 2.39455 14.5917 2.47955 12.4525 4.13705L5.35505 9.67621C3.93838 10.7812 2.81921 13.0479 2.81921 14.8329V24.6079C2.81921 28.2204 5.75171 31.167 9.36422 31.167H24.6359C28.2484 31.167 31.1809 28.2345 31.1809 24.622V15.017C31.1809 13.1045 29.9484 10.7529 28.3901 9.66205Z"
      fill="#083317"
    />
    <path
      d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V21.25C15.9375 20.6692 16.4192 20.1875 17 20.1875C17.5808 20.1875 18.0625 20.6692 18.0625 21.25V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
      fill="#E6EBE8"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIconColored",
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
