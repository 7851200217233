<template>
  <nav class="main-navigation">
    <ul>
      <li
        v-for="(item, index) in menu"
        :key="index"
        class="main-navigation__item"
      >
        <router-link class="main-navigation__link" :to="{ name: item.link }">
          <div class="top-decor"></div>
          <component
            :is="$route.name === item.link ? item.activeIcon : item.icon"
            class="mr-8 i-darkgrey"
            size="34"
          />
          {{ item.title }}
          <div class="bottom-decor"></div>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {
  HomeIcon,
  HomeIconColored,
  UserIcon,
  UserIconColored,
  UsersIcon,
  UsersIconColored,
  TruckIcon,
  TruckIconColored,
  TruckTimeIcon,
  TruckTimeIconColored,
} from "@/icons";

export default {
  name: "MainNavigation",
  components: {
    HomeIcon,
    HomeIconColored,
    UserIcon,
    UserIconColored,
    UsersIcon,
    UsersIconColored,
    TruckIcon,
    TruckIconColored,
    TruckTimeIcon,
    TruckTimeIconColored,
  },
  data: () => {
    return {
      menu: [
        {
          title: "Home",
          link: "Root",
          icon: "home-icon",
          activeIcon: "home-icon-colored",
        },
        {
          title: "View Residents",
          link: "ResidentsListPage",
          icon: "users-icon",
          activeIcon: "users-icon-colored",
        },
        {
          title: "View Companies",
          link: "CompaniesListPage",
          icon: "truck-icon",
          activeIcon: "truck-icon-colored",
        },
        {
          title: "New Companies",
          link: "NewCompaniesListPage",
          icon: "truck-time-icon",
          activeIcon: "truck-time-icon-colored",
        },
        {
          title: "Add Companies",
          link: "AddNewCompaniesListPage",
          icon: "truck-time-icon",
          activeIcon: "truck-time-icon-colored",
        },
        {
          title: "Profile",
          link: "ProfilePage",
          icon: "user-icon",
          activeIcon: "user-icon-colored",
        },
      ],
    };
  },
};
</script>
