<template>
  <svg viewBox="0 0 24 24" :width="size" :height="size" class="box-icon">
    <path
      d="M20.1 6.93998C20.1 7.47998 19.81 7.96995 19.35 8.21995L17.61 9.15995L16.13 9.94999L13.06 11.61C12.73 11.79 12.37 11.88 12 11.88C11.63 11.88 11.27 11.79 10.94 11.61L4.64999 8.21995C4.18999 7.96995 3.89999 7.47998 3.89999 6.93998C3.89999 6.39998 4.18999 5.90995 4.64999 5.65995L6.62 4.59996L8.19 3.74998L10.94 2.27C11.6 1.91 12.4 1.91 13.06 2.27L19.35 5.65995C19.81 5.90995 20.1 6.39998 20.1 6.93998Z"
    />
    <path
      d="M9.89999 12.7899L4.04999 9.85989C3.59999 9.62989 3.07999 9.65989 2.64999 9.91989C2.21999 10.1799 1.97 10.6399 1.97 11.1399V16.6699C1.97 17.6299 2.49999 18.4899 3.35999 18.9199L9.20999 21.8399C9.40999 21.9399 9.63001 21.9899 9.85001 21.9899C10.11 21.9899 10.37 21.9199 10.6 21.7699C11.03 21.5099 11.28 21.0499 11.28 20.5499V15.0199C11.29 14.0799 10.76 13.2199 9.89999 12.7899Z"
    />
    <path
      d="M22.03 11.1502V16.6801C22.03 17.6301 21.5 18.4901 20.64 18.9201L14.79 21.8501C14.59 21.9501 14.37 22.0001 14.15 22.0001C13.89 22.0001 13.63 21.9302 13.39 21.7802C12.97 21.5202 12.71 21.0601 12.71 20.5601V15.0401C12.71 14.0801 13.24 13.2201 14.1 12.7901L16.25 11.7201L17.75 10.9701L19.95 9.87013C20.4 9.64013 20.92 9.66012 21.35 9.93012C21.77 10.1901 22.03 10.6502 22.03 11.1502Z"
    />
    <path
      d="M17.61 9.15997L16.13 9.95001L6.62 4.59998L8.19 3.75L17.37 8.92999C17.47 8.98999 17.55 9.06997 17.61 9.15997Z"
      fill="#E0E0E0"
    />
    <path
      d="M17.75 10.9697V13.2397C17.75 13.6497 17.41 13.9897 17 13.9897C16.59 13.9897 16.25 13.6497 16.25 13.2397V11.7197L17.75 10.9697Z"
      fill="#E0E0E0"
    />
  </svg>
</template>

<script>
export default {
  name: "BoxIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
