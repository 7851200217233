<template>
  <svg viewBox="0 0 40 40" :width="size" :height="size" class="home-icon">
    <path
      d="M29.65 37.9168H10.35C5.78337 37.9168 2.08337 34.2001 2.08337 29.6334V17.2835C2.08337 15.0168 3.48337 12.1668 5.28337 10.7668L14.2667 3.76679C16.9667 1.66679 21.2834 1.56679 24.0834 3.53345L34.3834 10.7501C36.3667 12.1335 37.9167 15.1001 37.9167 17.5168V29.6501C37.9167 34.2001 34.2167 37.9168 29.65 37.9168ZM15.8 5.73345L6.81671 12.7335C5.63337 13.6668 4.58337 15.7835 4.58337 17.2835V29.6334C4.58337 32.8168 7.16671 35.4168 10.35 35.4168H29.65C32.8334 35.4168 35.4167 32.8335 35.4167 29.6501V17.5168C35.4167 15.9168 34.2667 13.7001 32.95 12.8001L22.65 5.58345C20.75 4.25012 17.6167 4.31679 15.8 5.73345Z"
    />
    <path
      d="M20 31.25C19.3167 31.25 18.75 30.6833 18.75 30V25C18.75 24.3167 19.3167 23.75 20 23.75C20.6833 23.75 21.25 24.3167 21.25 25V30C21.25 30.6833 20.6833 31.25 20 31.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
