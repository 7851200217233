<template>
  <div class="modal" @click="closeModaleventEmitter">
    <div class="modal-content-box" @click.stop>
      <div class="modal-header column-start">
        <div
          :class="[showCloseButton ? 'row-between row-baseline' : 'row-center']"
        >
          <slot name="title"></slot>
          <button
            v-if="showCloseButton"
            class="transparent-button pr-1"
            @click="$emit('close')"
          >
            <close-icon class="i-black" />
          </button>
        </div>
        <div>
          <slot name="description"></slot>
        </div>
      </div>
      <div class="modal-body ph-2">
        <slot name="body"></slot>
      </div>
      <div class="modal-footer ph-2">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { CloseIcon } from "@/icons";

export default {
  name: "ModalWindow",
  components: {
    CloseIcon,
  },
  emits: ["close"],
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    closeModalOnBackdropClicked: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeModaleventEmitter() {
      if (!this.closeModalOnBackdropClicked) {
        return;
      }

      this.$emit("close");
    },
  },
};
</script>
