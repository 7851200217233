import { ifNotAuthenticated } from "@/helpers/routerHelpers";

const authRoutes = [
  {
    path: "/auth/onboarding",
    name: "OnboardingPage",
    component: () =>
      import(
        /*webpackChunkName: "OnboardingPage" */ "@/views/auth/OnboardingPage.vue"
      ),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/pre-registration",
    name: "PreRegistration",
    component: () =>
      import(
        /*webpackChunkName: "OnboardingPage" */ "@/views/auth/PreRegistration.vue"
      ),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/auth/registration",
    name: "PreRegistrationRedirect",
    component: () =>
      import(
        /*webpackChunkName: "OnboardingPage" */ "@/views/auth/PreRegistrationRedirect.vue"
      ),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  // This page may be needed in the future.
  // {
  //   path: "/auth/signup",
  //   name: "SignUpPage",
  //   component: () =>
  //     import(/*webpackChunkName: "SignUpPage" */ "@/views/auth/SignUpPage.vue"),
  //   meta: { layout: "auth" },
  //   beforeEnter: ifNotAuthenticated,
  // },
  {
    path: "/auth/login",
    name: "LoginPage",
    component: () =>
      import(/* webpackChunkName: "LoginPage" */ "@/views/auth/LoginPage.vue"),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "@/views/auth/ForgotPassword.vue"
      ),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "@/views/auth/ResetPassword.vue"
      ),
    meta: { layout: "auth" },
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/auth/verify",
    name: "VerifyPage",
    props: { text: "Your email has been successfully verified!" },
    component: () =>
      import(
        /* webpackChunkName: "NotificationPage" */ "@/views/auth/NotificationPage.vue"
      ),
    meta: { layout: "auth" },
  },
  {
    path: "/auth/invite",
    name: "InvitePage",
    props: {
      text: "You have been invited to the app GRVO.\n Please install the application and follow the link again.",
    },
    component: () =>
      import(
        /* webpackChunkName: "NotificationPage" */ "@/views/auth/NotificationPage.vue"
      ),
    meta: { layout: "auth" },
  },
  {
    path: "/auth/stripe-return",
    name: "StripeReturnPage",
    props: {
      text: "Success! Please wait for Stripe approval.",
    },
    component: () =>
      import(
        /* webpackChunkName: "NotificationPage" */ "@/views/auth/NotificationPage.vue"
      ),
    meta: { layout: "auth" },
  },
  {
    path: "/auth/stripe-refresh",
    name: "StripeRefreshPage",
    props: {
      text: "Please return to GRVO app and finalize Stripe registration.",
    },
    component: () =>
      import(
        /* webpackChunkName: "NotificationPage" */ "@/views/auth/NotificationPage.vue"
      ),
    meta: { layout: "auth" },
  },
];

export default authRoutes;
