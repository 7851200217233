export const USER_TOKEN = "user-token";
export const USER_PROVIDER = "user-provider";

export const PROVIDER = {
  RESIDENT: "resident",
  MOVING_COMPANY: "moving_company",
  ADMIN: "admin",
};

export const SORT_BY = {
  NAME: "fullname",
  COMPANY_NAME: "company_name",
  EMAIL: "user.email",
  FEE: "fee",
  RATE: "rate",
  MOVES: "moves",
  COMPLETED: "completed",
};

export const ORDER_BY = {
  ASC: "asc",
  DESC: "desc",
};
