<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="truck-time-icon-colored"
  >
    <path
      d="M7.08317 1.41699C5.7515 1.41699 4.519 1.8845 3.5415 2.66367C2.23817 3.69783 1.4165 5.29866 1.4165 7.08366C1.4165 10.2145 3.95234 12.7503 7.08317 12.7503C8.514 12.7503 9.81736 12.212 10.8232 11.3337C11.999 10.2995 12.7498 8.78366 12.7498 7.08366C12.7498 3.95283 10.214 1.41699 7.08317 1.41699ZM8.49984 7.43783C8.49984 7.80616 8.30152 8.16035 7.98986 8.34451L6.21902 9.40701C6.04902 9.50618 5.86485 9.56283 5.68068 9.56283C5.32652 9.56283 4.97233 9.37868 4.774 9.05285C4.4765 8.54285 4.63234 7.89114 5.14234 7.59364L6.4032 6.84285V5.31283C6.4032 4.73199 6.88486 4.25033 7.4657 4.25033C8.04653 4.25033 8.5282 4.73199 8.5282 5.31283V7.43783H8.49984Z"
      fill="#2FA63B"
    />
    <path
      d="M21.2502 4.24967V16.9997C21.2502 18.558 19.9752 19.833 18.4168 19.833H2.8335V10.7947C3.86766 12.0272 5.45437 12.7922 7.21103 12.7497C8.64187 12.7213 9.931 12.1688 10.8943 11.248C11.3335 10.8797 11.7019 10.4122 11.9852 9.90216C12.4952 9.03799 12.7785 8.01797 12.7502 6.95547C12.7077 5.29797 11.971 3.83884 10.8235 2.83301H19.8335C20.6127 2.83301 21.2502 3.47051 21.2502 4.24967Z"
      fill="#083317"
    />
    <path
      d="M31.1668 19.833V24.083C31.1668 26.4347 29.2685 28.333 26.9168 28.333H25.5002C25.5002 26.7747 24.2252 25.4997 22.6668 25.4997C21.1085 25.4997 19.8335 26.7747 19.8335 28.333H14.1668C14.1668 26.7747 12.8918 25.4997 11.3335 25.4997C9.77516 25.4997 8.50016 26.7747 8.50016 28.333H7.0835C4.73183 28.333 2.8335 26.4347 2.8335 24.083V19.833H18.4168C19.9752 19.833 21.2502 18.558 21.2502 16.9997V7.08301H23.8569C24.8769 7.08301 25.8119 7.63552 26.3219 8.51385L28.7443 12.7497H26.9168C26.1377 12.7497 25.5002 13.3872 25.5002 14.1663V18.4163C25.5002 19.1955 26.1377 19.833 26.9168 19.833H31.1668Z"
      fill="#E6EBE8"
    />
    <path
      d="M11.3333 31.1667C12.8981 31.1667 14.1667 29.8981 14.1667 28.3333C14.1667 26.7685 12.8981 25.5 11.3333 25.5C9.76853 25.5 8.5 26.7685 8.5 28.3333C8.5 29.8981 9.76853 31.1667 11.3333 31.1667Z"
      fill="#083317"
    />
    <path
      d="M22.6668 31.1667C24.2316 31.1667 25.5002 29.8981 25.5002 28.3333C25.5002 26.7685 24.2316 25.5 22.6668 25.5C21.102 25.5 19.8335 26.7685 19.8335 28.3333C19.8335 29.8981 21.102 31.1667 22.6668 31.1667Z"
      fill="#083317"
    />
    <path
      d="M31.1667 17.7509V19.8333H26.9167C26.1375 19.8333 25.5 19.1958 25.5 18.4167V14.1667C25.5 13.3875 26.1375 12.75 26.9167 12.75H28.7441L30.7983 16.3483C31.0392 16.7733 31.1667 17.255 31.1667 17.7509Z"
      fill="#083317"
    />
  </svg>
</template>

<script>
export default {
  name: "TruckTimeIconColored",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
