<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="arrow-expand-icon"
    :style="{ transform: 'rotate(' + transform + 'deg)' }"
  >
    <path
      class="bg"
      d="M31.1666 22.9358L31.1666 11.0641C31.1666 5.90742 28.0925 2.83325 22.9358 2.83325L11.0783 2.83325C5.90746 2.83325 2.83329 5.90742 2.83329 11.0641L2.83329 22.9216C2.83329 28.0782 5.90746 31.1524 11.0641 31.1524L22.9358 31.1524C28.0925 31.1666 31.1666 28.0924 31.1666 22.9358Z"
    />
    <path
      d="M24.0834 23.7264L24.0834 16.8839C24.0834 16.3031 23.6017 15.8214 23.0209 15.8214C22.44 15.8214 21.9584 16.3031 21.9584 16.8839L21.9584 21.1623L11.0217 10.2256C10.6109 9.81477 9.93087 9.81477 9.52004 10.2256C9.30754 10.4381 9.20837 10.7073 9.20837 10.9764C9.20837 11.2456 9.30754 11.5148 9.52004 11.7273L20.4567 22.6639L16.1784 22.6639C15.5975 22.6639 15.1159 23.1456 15.1159 23.7264C15.1159 24.3073 15.5975 24.7889 16.1784 24.7889L23.0209 24.7889C23.6017 24.7889 24.0834 24.3073 24.0834 23.7264Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowExpandIcon",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
    transform: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
