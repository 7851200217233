<template>
  <svg viewBox="0 0 14 14" :width="size" :height="size" class="edit-icon">
    <path
      d="M3.23166 11.3866C2.87583 11.3866 2.54333 11.2641 2.30416 11.0366C2.00083 10.7508 1.85499 10.3191 1.90749 9.85245L2.12333 7.96245C2.16416 7.60661 2.37999 7.13411 2.63083 6.87745L7.41999 1.80828C8.61583 0.542447 9.86416 0.507447 11.13 1.70328C12.3958 2.89911 12.4308 4.14745 11.235 5.41328L6.44583 10.4824C6.20083 10.7449 5.74583 10.9899 5.38999 11.0483L3.51166 11.3691C3.41249 11.3749 3.32499 11.3866 3.23166 11.3866ZM9.29249 1.69745C8.84333 1.69745 8.45249 1.97745 8.05583 2.39745L3.26666 7.47245C3.14999 7.59495 3.01583 7.88661 2.99249 8.05578L2.77666 9.94578C2.75333 10.1383 2.79999 10.2958 2.90499 10.3949C3.00999 10.4941 3.16749 10.5291 3.35999 10.4999L5.23833 10.1791C5.40749 10.1499 5.68749 9.99828 5.80416 9.87578L10.5933 4.80661C11.3167 4.03661 11.5792 3.32495 10.5233 2.33328C10.0567 1.88411 9.65416 1.69745 9.29249 1.69745Z"
    />
    <path
      d="M10.1151 6.38745C10.1034 6.38745 10.0859 6.38745 10.0743 6.38745C8.25426 6.20662 6.79009 4.82412 6.51009 3.01579C6.47509 2.77662 6.63842 2.55495 6.87759 2.51412C7.11676 2.47912 7.33842 2.64245 7.37926 2.88162C7.60092 4.29329 8.74426 5.37829 10.1676 5.51829C10.4068 5.54162 10.5818 5.75745 10.5584 5.99662C10.5293 6.21829 10.3368 6.38745 10.1151 6.38745Z"
    />
    <path
      d="M12.25 13.2709H1.75C1.51083 13.2709 1.3125 13.0725 1.3125 12.8334C1.3125 12.5942 1.51083 12.3959 1.75 12.3959H12.25C12.4892 12.3959 12.6875 12.5942 12.6875 12.8334C12.6875 13.0725 12.4892 13.2709 12.25 13.2709Z"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
  props: {
    size: {
      type: [Number, String],
      default: 14,
    },
  },
};
</script>
