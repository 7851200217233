<template>
  <svg
    viewBox="0 0 15 14"
    :width="size"
    :height="size"
    class="context-menu-icon"
  >
    <path
      d="M7.49998 4.66671C8.14165 4.66671 8.66665 4.14171 8.66665 3.50004C8.66665 2.85837 8.14165 2.33337 7.49998 2.33337C6.85831 2.33337 6.33331 2.85837 6.33331 3.50004C6.33331 4.14171 6.85831 4.66671 7.49998 4.66671ZM7.49998 5.83337C6.85831 5.83337 6.33331 6.35837 6.33331 7.00004C6.33331 7.64171 6.85831 8.16671 7.49998 8.16671C8.14165 8.16671 8.66665 7.64171 8.66665 7.00004C8.66665 6.35837 8.14165 5.83337 7.49998 5.83337ZM7.49998 9.33337C6.85831 9.33337 6.33331 9.85837 6.33331 10.5C6.33331 11.1417 6.85831 11.6667 7.49998 11.6667C8.14165 11.6667 8.66665 11.1417 8.66665 10.5C8.66665 9.85837 8.14165 9.33337 7.49998 9.33337Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ContextMenuIcon",
  props: {
    size: {
      type: [Number, String],
      default: 15,
    },
  },
};
</script>
