<template>
  <div class="table">
    <div class="table__header row-start row-baseline">
      <div
        class="lg-3 pointer"
        @click="
          sortData(!isTableForCompanies ? SORT_BY.NAME : SORT_BY.COMPANY_NAME)
        "
      >
        <!-- <checkbox-button class="s-align-5 mr-3">
          <input type="checkbox" v-model="selectAllRows" />
        </checkbox-button> -->
        Name
        <sorting-icon
          class="i-darkgrey ml-3"
          :transform="
            iconSortDirection[SORT_BY.NAME] === ORDER_BY.ASC ||
            iconSortDirection[SORT_BY.COMPANY_NAME] === ORDER_BY.ASC
              ? '0'
              : '180'
          "
        />
      </div>
      <div
        :class="['pointer', isTableForCompanies ? 'lg-3' : 'lg-4']"
        @click="sortData(SORT_BY.EMAIL)"
      >
        Email
        <sorting-icon
          class="i-darkgrey ml-3"
          :transform="
            iconSortDirection[SORT_BY.EMAIL] === ORDER_BY.ASC ? '0' : '180'
          "
        />
      </div>
      <div
        :class="['pointer', isTableForCompanies ? 'lg-1' : 'lg-2']"
        @click="sortData(SORT_BY.MOVES)"
      >
        Moves
        <sorting-icon
          class="i-darkgrey ml-3"
          :transform="
            iconSortDirection[SORT_BY.MOVES] === ORDER_BY.ASC ? '0' : '180'
          "
        />
      </div>
      <div class="lg-1">Info</div>
      <div
        v-if="isTableForCompanies"
        class="lg-1 pointer"
        @click="sortData(SORT_BY.FEE)"
      >
        Fee (%)
        <sorting-icon
          class="i-darkgrey ml-3"
          :transform="
            iconSortDirection[SORT_BY.FEE] === ORDER_BY.ASC ? '0' : '180'
          "
        />
      </div>
      <div
        v-if="isTableForCompanies"
        class="lg-1 pointer"
        @click="sortData(SORT_BY.RATE)"
      >
        Raiting
        <sorting-icon
          class="i-darkgrey ml-3"
          :transform="
            iconSortDirection[SORT_BY.RATE] === ORDER_BY.ASC ? '0' : '180'
          "
        />
      </div>
      <div class="lg-1">Block</div>
      <div class="lg-1">Status</div>
    </div>
    <div
      class="table__body row-start row-baseline"
      v-for="item in data"
      :key="item.user_id"
    >
      <div class="lg-3">
        <!-- <checkbox-button class="s-align-5 mr-3">
          <input
            type="checkbox"
            v-model="item.row_is_selected"
            @change="selectRow(item, $event)"
          />
        </checkbox-button> -->
        {{ isTableForCompanies ? item.company_name : item.fullname }}
      </div>
      <div
        :class="['ellipsis', isTableForCompanies ? 'lg-3' : 'lg-4']"
        :title="item.user.email"
      >
        {{ item.user.email }}
      </div>
      <div :class="['pl-2', isTableForCompanies ? 'lg-1' : 'lg-2']">
        {{ item.moves }}
      </div>
      <div class="lg-1 underline pointer" @click="toSingleItem(item)">View</div>
      <div v-if="isTableForCompanies" class="lg-1">
        {{ item.fee }}
      </div>
      <div v-if="isTableForCompanies" class="lg-1">
        {{ item.rate ? item.rate : "-" }}
      </div>
      <div class="lg-1">
        <toggle-switch>
          <input
            type="checkbox"
            :checked="userIsBlocked(item)"
            @change="toggleUserStatus(item, $event.target.checked)"
          />
        </toggle-switch>
      </div>
      <div class="lg-1">
        {{ item.user.is_blocked ? "Blocked" : "Active" }}
      </div>
    </div>
    <!-- <p
      v-if="showDeleteButton"
      class="uppercase mt-30 pointer f-sbold f-14-red"
      @click="deleteRows"
    >
      Delete from list
    </p> -->
  </div>
</template>

<script>
import { SortingIcon } from "@/icons";
import { SORT_BY, ORDER_BY } from "@/constants/commonConstants";

export default {
  name: "EntityListTable",
  components: {
    SortingIcon,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isTableForCompanies: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    sortBy: {
      type: String,
      required: true,
    },
    orderBy: {
      type: String,
      required: true,
    },
  },
  emits: ["sortData", "changeUserStatus"],
  data() {
    return {
      // selectAllRows: false,
      selectedRows: [],
      SORT_BY: SORT_BY,
      ORDER_BY: ORDER_BY,
      iconSortDirection: {},
    };
  },
  computed: {
    /* showDeleteButton() {
      return !!this.selectedRows.length;
    }, */
  },
  watch: {
    /* selectAllRows(newVal) {
      this.selectedRows = [];
      this.data.map((el) => {
        el.row_is_selected = newVal;

        if (newVal) {
          this.selectedRows.push(el.id);
        } else {
          this.selectedRows = [];
        }
      });
    }, */
  },
  methods: {
    /* selectRow(item, event) {
      if (event.target.checked && !this.selectedRows.includes(item.id)) {
        this.selectedRows.push(item.id);
      }

      if (!event.target.checked && this.selectedRows.includes(item.id)) {
        const elIndex = this.selectedRows.findIndex((el) => el === item.id);
        this.selectedRows.splice(elIndex, 1);
      }

      if (!this.selectedRows.length) {
        this.selectAllRows = false;
      }
    },
    deleteRows() {}, */
    toSingleItem({ user_id }) {
      switch (this.$route.name) {
        case "ResidentsListPage":
          {
            this.$router.push({
              name: "SingleResident",
              params: {
                entityId: user_id,
              },
            });
          }
          break;
        case "CompaniesListPage":
          {
            this.$router.push({
              name: "SingleCompanyPage",
              params: {
                entityId: user_id,
              },
            });
          }
          break;
      }
    },
    sortData(sortBy) {
      let order =
        this.orderBy === this.ORDER_BY.ASC
          ? this.ORDER_BY.DESC
          : this.ORDER_BY.ASC;

      this.iconSortDirection[sortBy] = order;
      this.$emit("sortData", {
        sort: sortBy,
        order: order,
      });
    },
    userIsBlocked({ user }) {
      return !!user.is_blocked;
    },
    toggleUserStatus({ user }, isBlocked) {
      this.$emit("changeUserStatus", { userId: user.id, block: isBlocked });
    },
  },
  created() {
    Object.values(this.SORT_BY).forEach((el) => {
      this.iconSortDirection[el] = ORDER_BY.ASC;
    });
  },
};
</script>
