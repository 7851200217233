<template>
  <svg
    viewBox="0 0 21 21"
    :width="size"
    :height="size"
    class="sorting-icon"
    :style="{ transform: `rotate(${transform}deg)` }"
  >
    <path
      d="M9.2085 6.29724C9.05017 6.29724 8.89181 6.23893 8.76681 6.11393L6.10848 3.4556L3.45014 6.11393C3.20847 6.3556 2.80848 6.3556 2.56681 6.11393C2.32514 5.87227 2.32514 5.47227 2.56681 5.2306L5.66684 2.13057C5.7835 2.01391 5.94181 1.94727 6.10848 1.94727C6.27514 1.94727 6.4335 2.01391 6.55017 2.13057L9.65014 5.2306C9.89181 5.47227 9.89181 5.87227 9.65014 6.11393C9.52514 6.23893 9.36684 6.29724 9.2085 6.29724Z"
    />
    <path
      d="M6.10834 18.1973C5.76667 18.1973 5.48334 17.9139 5.48334 17.5723V2.57227C5.48334 2.2306 5.76667 1.94727 6.10834 1.94727C6.45 1.94727 6.73334 2.2306 6.73334 2.57227V17.5723C6.73334 17.9139 6.45 18.1973 6.10834 18.1973Z"
    />
    <!-- <path
      d="M14.9 18.1975C14.7333 18.1975 14.575 18.1309 14.4583 18.0142L11.3583 14.9142C11.1166 14.6725 11.1166 14.2725 11.3583 14.0309C11.6 13.7892 12 13.7892 12.2416 14.0309L14.9 16.6892L17.5583 14.0309C17.7999 13.7892 18.2 13.7892 18.4416 14.0309C18.6833 14.2725 18.6833 14.6725 18.4416 14.9142L15.3416 18.0142C15.225 18.1309 15.0583 18.1975 14.9 18.1975Z"
    />
    <path
      d="M14.8917 18.1973C14.55 18.1973 14.2667 17.9139 14.2667 17.5723V2.57227C14.2667 2.2306 14.55 1.94727 14.8917 1.94727C15.2333 1.94727 15.5167 2.2306 15.5167 2.57227V17.5723C15.5167 17.9139 15.2417 18.1973 14.8917 18.1973Z"
    /> -->
  </svg>
</template>

<script>
export default {
  name: "SortingIcon",
  props: {
    size: {
      type: [Number, String],
      default: 21,
    },
    transform: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
