<template>
  <div :id="`context-menu-container${unicId}`" class="relative">
    <button
      @click.stop.prevent="toggleOpenContextMenu(unicId)"
      :class="btnStyle"
      :disabled="disabled"
    >
      <component :is="iconTriger" class="i-darkgrey" />
      <span class="f-14-darkgrey f-normal ml-4">
        {{ contextControlTitle ? contextControlTitle : "" }}
      </span>
    </button>
    <div
      :class="['context-menu', !isShowPopup && 'visiblity-hidden']"
      :id="unicId"
      :style="`${stylePopup};${widthContextMenu}`"
      v-if="open"
      @click.stop
    >
      <span class="pointer row-end" @click="toggleOpenContextMenu(unicId)">
        <close-icon class="i-black" size="30" />
      </span>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import { ContextMenuIcon, CloseIcon, FilterIcon } from "@/icons";
/*
 * coordinates: any styles for this component
 * usually: top, left, right or bottom
 *
 * unicId: this component is often displayed in a loop,
 * so everyone needs to set a unique id
 *
 *
 * btnStyle: class for menu icon
 */

export default {
  name: "ContextMenu",
  props: {
    width: {
      type: [String, Number],
      default: "",
    },
    unicId: {
      type: String,
      required: true,
    },
    btnStyle: {
      type: String,
      default: "transparent-button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconTriger: {
      type: String,
      default: "context-menu-icon",
    },
    contextControlTitle: {
      type: String,
      required: false,
    },
  },
  components: {
    ContextMenuIcon,
    CloseIcon,
    FilterIcon,
  },
  data() {
    return {
      open: false,
      scrollHeight: 0,
      stylePopup: "",
      isShowPopup: false,
    };
  },
  computed: {
    widthContextMenu() {
      return this.width ? `width: ${this.width}px` : null;
    },
  },
  methods: {
    getCoords(element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.documentElement.scrollLeft;

      let clientTop =
        document.documentElement.clientTop ||
        document.documentElement.clientTop ||
        0;
      let clientLeft =
        document.documentElement.clientLeft ||
        document.documentElement.clientLeft ||
        0;

      let box = element.getBoundingClientRect();

      return {
        top: box.top + scrollTop - clientTop,
        left: box.left + scrollLeft - clientLeft,
      };
    },
    showDateMenu() {
      this.stylePopup = "";
      const popup = document.getElementById(this.unicId);
      const container = document.getElementById(
        `context-menu-container${this.unicId}`
      );
      const freeHeightBottom =
        this.scrollHeight -
        this.getCoords(container).top -
        container.clientHeight;
      if (
        freeHeightBottom < popup.clientHeight ||
        container.getBoundingClientRect().top >
          window.outerHeight - container.getBoundingClientRect().top
      ) {
        const marginTop = Math.round(popup.clientHeight);
        this.stylePopup = `top: -${marginTop}px;`;
      }
    },
    toggleOpenContextMenu() {
      this.open = !this.open;

      setTimeout(() => {
        if (this.open) {
          this.showDateMenu();
          this.isShowPopup = true;
        } else {
          this.isShowPopup = false;
        }
      }, 0);
    },
    outside(event) {
      if (
        !this.$el.contains(event.target) ||
        (document.getElementById(this.unicId) &&
          document.getElementById(this.unicId).contains(event.target))
      ) {
        this.open = false;
      }
    },
  },
  mounted() {
    document.body.addEventListener("click", this.outside);
    this.scrollHeight = document.body.offsetHeight;
  },
  unmounted() {
    document.body.removeEventListener("click", this.outside);
  },
};
</script>
