<template>
  <form class="searchbar" @submit.prevent>
    <span class="search-icon-container">
      <search-icon class="i-darkgrey" />
    </span>
    <input
      type="text"
      placeholder="Search"
      v-model="searchQuery"
      @input="searchByQuery"
    />
    <span
      v-show="searchQuery.length"
      class="close-icon-container pointer"
      @click="clearInput"
    >
      <close-icon class="i-black" />
    </span>
  </form>
</template>

<script>
import { SearchIcon, CloseIcon } from "@/icons";

export default {
  name: "SearchBar",
  components: {
    SearchIcon,
    CloseIcon,
  },
  data() {
    return {
      searchQuery: "",
      debounce: null,
    };
  },
  emits: ["searchByQuery", "resetSearch"],
  methods: {
    searchByQuery() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (this.searchQuery.length) {
          this.$emit("searchByQuery", this.searchQuery);
        } else {
          this.$emit("resetSearch");
        }
      }, 750);
    },
    clearInput() {
      this.$emit("resetSearch");
      clearTimeout(this.debounce);
      this.searchQuery = "";
    },
  },
  beforeUnmount() {
    clearTimeout(this.debounce);
  },
};
</script>
