import { ifAuthenticated } from "@/helpers/routerHelpers";

const companiesRoutes = [
  {
    path: "/companies",
    name: "CompaniesListPage",
    component: () =>
      import(
        /*webpackChunkName: "CompaniesListPage" */ "@/views/companies/CompaniesListPage.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/company/:entityId",
    name: "SingleCompanyPage",
    props: true,
    component: () =>
      import(
        /*webpackChunkName: "SingleCompanyPage" */ "@/views/companies/SingleCompanyPage.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/new-companies",
    name: "NewCompaniesListPage",
    component: () =>
      import(
        /*webpackChunkName: "NewCompaniesListPage" */ "@/views/companies/NewCompaniesListPage.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/add-new-companies",
    name: "AddNewCompaniesListPage",
    component: () =>
      import(
        /*webpackChunkName: "AddCompaniesListPage" */ "@/views/companies/AddCompaniesListPage.vue"
      ),
    meta: { layout: "main" },
    beforeEnter: ifAuthenticated,
  },
];

export default companiesRoutes;
