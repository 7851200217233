<template>
  <div class="table">
    <div class="table__header row-start row-baseline">
      <div class="lg-1">Moves</div>
      <div :class="[isTableForCompanies ? 'lg-7' : 'lg-6', 's-align-5']">
        Direction
      </div>
      <div v-if="!isTableForCompanies" class="lg-1">Inventory List</div>
      <div class="lg-1 s-align-5">Mileage</div>
      <div v-if="!isTableForCompanies" class="lg-3 s-align-5">
        Moving Company
      </div>
      <div v-if="isTableForCompanies" class="lg-1 s-align-5">Raiting</div>
      <div v-if="isTableForCompanies" class="lg-2 s-align-5">Fee</div>
    </div>
    <div
      class="table__body row-start row-baseline"
      v-for="(item, index) in data"
      :key="item.id"
    >
      <div class="lg-1 pl-20"># {{ index + 1 }}</div>
      <div
        :class="[isTableForCompanies ? 'lg-7' : 'lg-6', 's-align-5 t-center']"
      >
        {{ direction(item) }}
      </div>
      <div
        v-if="!isTableForCompanies"
        class="lg-1 s-align-5 underline pointer"
        @click="toInventoryList(item)"
      >
        View
      </div>
      <div class="lg-1 s-align-5">{{ mileage(item) }}</div>
      <div v-if="!isTableForCompanies" class="lg-3 s-align-5">
        {{ item.moving_company }}
      </div>
      <div v-if="isTableForCompanies" class="lg-1 s-align-5">
        {{ item?.estimate?.rate ? item.estimate.rate : "-" }}
      </div>
      <div v-if="isTableForCompanies" class="lg-2 s-align-5">
        {{ price(item) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleEntityTable",
  components: {},
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isTableForCompanies: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    residentName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toInventoryList({ id }) {
      this.$router.push({
        name: "InventoryList",
        params: {
          entityId: this.$route.params.entityId,
          id,
        },
        query: {
          resident: this.residentName,
        },
      });
    },
    direction({ address_from, address_to, estimate }) {
      if (this.isTableForCompanies) {
        return `${estimate?.address_from ? estimate.address_from : ""} to ${
          estimate?.address_to ? estimate.address_to : ""
        }`;
      } else {
        return `${address_from ? address_from : ""} to ${
          address_to ? address_to : ""
        }`;
      }
    },
    mileage({ mileage, estimate }) {
      if (this.isTableForCompanies) {
        return `${estimate?.mileage ? estimate?.mileage + " miles" : "-"}`;
      } else {
        return mileage ? mileage + " miles" : "-";
      }
    },
    price({ price }) {
      return `${price ? price + " $" : "-"}`;
    },
  },
};
</script>
