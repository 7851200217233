<template>
  <svg
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
    class="arrow-icon"
    :style="{ transform: 'rotate(' + transform + 'deg)' }"
  >
    <path
      d="M18.07 10.3204C17.88 10.3204 17.69 10.2504 17.54 10.1004L12 4.56043L6.46 10.1004C6.17 10.3904 5.69 10.3904 5.4 10.1004C5.11 9.81043 5.11 9.33043 5.4 9.04043L11.47 2.97043C11.76 2.68043 12.24 2.68043 12.53 2.97043L18.6 9.04043C18.89 9.33043 18.89 9.81043 18.6 10.1004C18.46 10.2504 18.26 10.3204 18.07 10.3204Z"
    />
    <path
      d="M12 21.2499C11.59 21.2499 11.25 20.9099 11.25 20.4999V3.66992C11.25 3.25992 11.59 2.91992 12 2.91992C12.41 2.91992 12.75 3.25992 12.75 3.66992V20.4999C12.75 20.9099 12.41 21.2499 12 21.2499Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    transform: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
