import api from "@/api/api.js";

const URLS = {
  dashboard: "/admin/dashboard",
};

export default {
  getDashboard(payload) {
    return api.get(URLS.dashboard, { params: payload });
  },
};
