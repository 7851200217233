<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="users-icon-colored"
  >
    <path
      d="M24.8342 11.0072C24.7351 10.993 24.6359 10.993 24.5367 11.0072C22.3409 10.9363 20.5984 9.13717 20.5984 6.92717C20.5984 4.67467 22.4259 2.83301 24.6926 2.83301C26.9451 2.83301 28.7867 4.66051 28.7867 6.92717C28.7726 9.13717 27.0301 10.9363 24.8342 11.0072Z"
      fill="#083317"
    />
    <path
      d="M29.4524 20.8246C27.8658 21.8871 25.6416 22.2837 23.5874 22.0146C24.1258 20.8529 24.4091 19.5638 24.4233 18.2038C24.4233 16.7871 24.1116 15.4413 23.5166 14.2654C25.6133 13.9821 27.8374 14.3787 29.4383 15.4412C31.6766 16.9146 31.6766 19.3371 29.4524 20.8246Z"
      fill="#083317"
    />
    <path
      d="M7.9525 11.0072C8.05166 10.993 8.15083 10.993 8.25 11.0072C10.4458 10.9363 12.1883 9.13717 12.1883 6.92717C12.1883 4.67467 10.3608 2.83301 8.09417 2.83301C5.84167 2.83301 4 4.66051 4 6.92717C4.01417 9.13717 5.75666 10.9363 7.9525 11.0072Z"
      fill="#083317"
    />
    <path
      d="M10.4529 18.204C10.4529 19.5782 10.7504 20.8815 11.2887 22.0574C9.29125 22.2699 7.20875 21.8449 5.67875 20.839C3.44042 19.3515 3.44042 16.929 5.67875 15.4415C7.19458 14.4215 9.33375 14.0107 11.3454 14.2374C10.7646 15.4274 10.4529 16.7732 10.4529 18.204Z"
      fill="#083317"
    />
    <path
      d="M17.17 22.4825C17.0567 22.4683 16.9292 22.4683 16.8017 22.4825C14.1951 22.3975 12.1125 20.2583 12.1125 17.6233C12.1125 14.9317 14.2801 12.75 16.9859 12.75C19.6776 12.75 21.8592 14.9317 21.8592 17.6233C21.8592 20.2583 19.7909 22.3975 17.17 22.4825Z"
      fill="#083317"
    />
    <path
      d="M12.5658 25.4152C10.4266 26.8461 10.4266 29.1977 12.5658 30.6144C15.0025 32.2436 18.9975 32.2436 21.4341 30.6144C23.5733 29.1836 23.5733 26.8319 21.4341 25.4152C19.0116 23.7861 15.0166 23.7861 12.5658 25.4152Z"
      fill="#083317"
    />
  </svg>
</template>

<script>
export default {
  name: "UsersIconColored",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
