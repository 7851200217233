<template>
  <div class="pointer">
    <vue-select
      class="vue-select__small pointer"
      :options="options"
      v-model="selectedOption"
      close-on-select
      openDirection="bottom"
      @selected="selectOptions"
    >
    </vue-select>
  </div>
</template>

<script>
export default {
  name: "TimeFilter",
  emits: ["selectedPeriod"],
  data: () => {
    return {
      selectedOption: "Day",
      options: ["Day", "Week", "Month", "Year"],
    };
  },
  methods: {
    selectOptions(value) {
      this.$emit("selectedPeriod", value);
    },
  },
};
</script>
