<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="user-icon-colored"
  >
    <path
      d="M17.0002 2.83301C13.2885 2.83301 10.271 5.85051 10.271 9.56217C10.271 13.203 13.1185 16.1497 16.8302 16.2772C16.9435 16.263 17.0568 16.263 17.1418 16.2772C17.1702 16.2772 17.1843 16.2772 17.2127 16.2772C17.2268 16.2772 17.2268 16.2772 17.241 16.2772C20.8677 16.1497 23.7152 13.203 23.7293 9.56217C23.7293 5.85051 20.7118 2.83301 17.0002 2.83301Z"
      fill="#083317"
    />
    <path
      d="M24.1969 20.0456C20.2444 17.4106 13.7985 17.4106 9.81769 20.0456C8.01852 21.2498 7.02686 22.8789 7.02686 24.6214C7.02686 26.3639 8.01852 27.9789 9.80352 29.1689C11.7869 30.5006 14.3935 31.1664 17.0002 31.1664C19.6069 31.1664 22.2135 30.5006 24.1969 29.1689C25.9819 27.9648 26.9735 26.3498 26.9735 24.5931C26.9594 22.8506 25.9819 21.2356 24.1969 20.0456Z"
      fill="#083317"
    />
  </svg>
</template>

<script>
export default {
  name: "UserIconColored",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
