<template>
  <div v-if="!loadingData">
    <h1 class="row-baseline mb-30 f-36-black f-medium">
      Welcome to GRVO, {{ profile.fullname }}!
    </h1>
    <div class="row-start mb-40">
      <div class="lg-6 pr-8">
        <leader-card :leadersData="leaders" @sortData="getSortedLeadersData" />
      </div>
      <div class="lg-6 pl-8">
        <!-- Do not delete, may be needed in the future -->
        <!-- <statistics-card /> -->
      </div>
    </div>
    <div class="row-start">
      <div class="lg-6 pr-8">
        <div class="row-start">
          <div class="lg-6 column-start pr-8">
            <up-or-down-indicators
              title="Gross Revenue"
              :statisticData="grossRevenue"
            />
          </div>
          <div class="lg-6 column-start pl-8">
            <up-or-down-indicators
              title="Movers Earings"
              :statisticData="moversEarnings"
            />
          </div>
        </div>
      </div>
      <div class="lg-6 pl-8">
        <!-- Do not delete, may be needed in the future -->
        <!-- <profit-card /> -->
        <div class="row-start">
          <div class="lg-6 column-start">
            <up-or-down-indicators title="Profit" :statisticData="profit" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="h-100 s-align-5" v-show="loadingData">
    <div>
      <load-spinner />
    </div>
  </div>
  <load-spinner size="full" v-show="sortedDataLoaded" />
</template>

<script>
import {
  LeaderCard,
  // StatisticsCard,
  UpOrDownIndicators,
  // ProfitCard,
} from "@/components";
import DashboardService from "@/services/DashboardService";
import NotificationsService from "@/services/NotificationsService";
import { SORT_BY, ORDER_BY } from "@/constants/commonConstants";
import UsersService from "@/services/UsersService";

export default {
  name: "RootView",
  components: {
    LeaderCard,
    // StatisticsCard,
    UpOrDownIndicators,
    // ProfitCard,
  },
  data: () => {
    return {
      ORDER_BY,
      SORT_BY,
      loadingData: false,
      sortedDataLoaded: false,
      dashboardData: {},
      initialData: {
        last_month: 0,
        last_year: 0,
      },
      orderBy: ORDER_BY.DESC,
      profile: {},
    };
  },
  computed: {
    leaders() {
      return this.dashboardData?.leaderboard || [];
    },
    grossRevenue() {
      if (!this.dashboardData?.gross_revenue) {
        return this.initialData;
      }

      return this.dashboardData.gross_revenue;
    },
    moversEarnings() {
      if (!this.dashboardData?.movers_earnings) {
        return this.initialData;
      }

      return this.dashboardData.movers_earnings;
    },
    profit() {
      if (!this.dashboardData?.profit) {
        return this.initialData;
      }

      return this.dashboardData.profit;
    },
  },
  methods: {
    async getDashboard(sortParams) {
      if (!sortParams) {
        this.loadingData = true;
      } else {
        this.sortedDataLoaded = true;
      }

      try {
        let requestParams = {
          order: this.orderBy,
        };

        if (sortParams) {
          requestParams = sortParams;
        }

        const profileResponse = await UsersService.getProfile();
        this.profile = profileResponse?.data?.data || {};
        const response = await DashboardService.getDashboard(requestParams);
        this.dashboardData = response?.data?.data || {};
      } catch (err) {
        NotificationsService.requestErrorNotification(err);
      }

      this.loadingData = false;
      this.sortedDataLoaded = false;
    },
    getSortedLeadersData(sortParams) {
      this.getDashboard(sortParams);
    },
  },
  created() {
    this.getDashboard(null);
  },
};
</script>
