<template>
  <svg viewBox="0 0 24 24" :width="size" :height="size" class="filter-icon">
    <path
      d="M10.9399 22.6496C10.4599 22.6496 9.9899 22.5296 9.5499 22.2896C8.6699 21.7996 8.1399 20.9096 8.1399 19.9096V14.6096C8.1399 14.1096 7.8099 13.3596 7.4999 12.9796L3.7599 9.01961C3.1299 8.38961 2.6499 7.30961 2.6499 6.49961V4.19961C2.6499 2.59961 3.8599 1.34961 5.3999 1.34961H18.5999C20.1199 1.34961 21.3499 2.57961 21.3499 4.09961V6.29961C21.3499 7.34961 20.7199 8.53961 20.1299 9.12961L15.7999 12.9596C15.3799 13.3096 15.0499 14.0796 15.0499 14.6996V18.9996C15.0499 19.8896 14.4899 20.9196 13.7899 21.3396L12.4099 22.2296C11.9599 22.5096 11.4499 22.6496 10.9399 22.6496ZM5.3999 2.84961C4.6999 2.84961 4.1499 3.43961 4.1499 4.19961V6.49961C4.1499 6.86961 4.4499 7.58961 4.8299 7.96961L8.6399 11.9796C9.1499 12.6096 9.6499 13.6596 9.6499 14.5996V19.8996C9.6499 20.5496 10.0999 20.8696 10.2899 20.9696C10.7099 21.1996 11.2199 21.1996 11.6099 20.9596L12.9999 20.0696C13.2799 19.8996 13.5599 19.3596 13.5599 18.9996V14.6996C13.5599 13.6296 14.0799 12.4496 14.8299 11.8196L19.1099 8.02961C19.4499 7.68961 19.8599 6.87961 19.8599 6.28961V4.09961C19.8599 3.40961 19.2999 2.84961 18.6099 2.84961H5.3999Z"
    />
    <path
      d="M5.99992 10.7503C5.85992 10.7503 5.72992 10.7103 5.59992 10.6403C5.24992 10.4203 5.13992 9.95026 5.35992 9.60026L10.2899 1.70026C10.5099 1.35026 10.9699 1.24026 11.3199 1.46026C11.6699 1.68026 11.7799 2.14026 11.5599 2.49026L6.62992 10.3903C6.48992 10.6203 6.24992 10.7503 5.99992 10.7503Z"
    />
  </svg>
</template>

<script>
export default {
  name: "FilterIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
