import { AUTH_LOGIN, AUTH_SUCCESS, AUTH_LOGOUT } from "../actions/auth";
import router from "@/router/index";
import AuthService from "@/services/AuthService";
import NotificationsService from "@/services/NotificationsService";
import {
  USER_TOKEN,
  USER_PROVIDER,
  PROVIDER,
} from "@/constants/commonConstants";
import { axiosInstance } from "@/api/api.js";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem(USER_TOKEN) || "",
    provider: localStorage.getItem(USER_PROVIDER) || "",
  },
  getters: {
    isAuth: (state) => {
      try {
        return !!state.token && state.provider === PROVIDER.ADMIN;
      } catch (e) {
        // throws InvalidTokenError exception
        state.token = "";
        localStorage.removeItem(USER_TOKEN);
        localStorage.removeItem(USER_PROVIDER);
        return false;
      }
    },
  },
  mutations: {
    [AUTH_SUCCESS](state, payload) {
      state.token = payload.token;
      state.provider = payload.provider;
      localStorage.setItem(USER_TOKEN, payload.token);
      localStorage.setItem(USER_PROVIDER, payload.provider);
      axiosInstance.defaults.headers["Authorization"] = `Bearer ${
        localStorage.getItem("user-token") || ""
      }`;
      router.push({ name: "Root" });
    },
    [AUTH_LOGOUT](state) {
      state.token = "";
      state.provider = "";
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(USER_PROVIDER);
      router.push({ name: "OnboardingPage" });
    },
  },
  actions: {
    [AUTH_LOGIN]({ commit }, formData) {
      return new Promise((resolve, reject) => {
        AuthService.login(formData)
          .then((response) => response.data.data)
          .then((data) => {
            commit(AUTH_SUCCESS, data);
            resolve(data);
          })
          .catch((error) => {
            NotificationsService.requestErrorNotification(error);
            reject(error);
          });
      });
    },
    [AUTH_LOGOUT]({ commit }) {
      return new Promise((resolve, reject) => {
        AuthService.logout()
          .then((response) => response.data.data)
          .then((data) => {
            commit(AUTH_LOGOUT);
            resolve(data);
          })
          .catch((error) => {
            NotificationsService.requestErrorNotification(error);
            reject(error);
          });
      });
    },
  },
};
