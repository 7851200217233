<template>
  <svg
    viewBox="0 0 34 34"
    :width="size"
    :height="size"
    class="truck-icon-colored"
  >
    <path
      d="M21.2502 4.25065V17.0006C21.2502 18.559 19.9752 19.834 18.4168 19.834H2.8335V8.50065C2.8335 5.36982 5.36933 2.83398 8.50016 2.83398H19.8335C20.6127 2.83398 21.2502 3.47148 21.2502 4.25065Z"
      fill="#083317"
    />
    <path
      d="M31.1668 19.834V24.084C31.1668 26.4356 29.2685 28.334 26.9168 28.334H25.5002C25.5002 26.7757 24.2252 25.5007 22.6668 25.5007C21.1085 25.5007 19.8335 26.7757 19.8335 28.334H14.1668C14.1668 26.7757 12.8918 25.5007 11.3335 25.5007C9.77516 25.5007 8.50016 26.7757 8.50016 28.334H7.0835C4.73183 28.334 2.8335 26.4356 2.8335 24.084V19.834H18.4168C19.9752 19.834 21.2502 18.559 21.2502 17.0007V7.08398H23.8568C24.8768 7.08398 25.8118 7.6365 26.3218 8.51483L28.7443 12.7507H26.9168C26.1377 12.7507 25.5002 13.3882 25.5002 14.1673V18.4173C25.5002 19.1965 26.1377 19.834 26.9168 19.834H31.1668Z"
      fill="#E6EBE8"
    />
    <path
      d="M11.3333 31.1667C12.8981 31.1667 14.1667 29.8981 14.1667 28.3333C14.1667 26.7685 12.8981 25.5 11.3333 25.5C9.76853 25.5 8.5 26.7685 8.5 28.3333C8.5 29.8981 9.76853 31.1667 11.3333 31.1667Z"
      fill="#083317"
    />
    <path
      d="M22.6668 31.1667C24.2316 31.1667 25.5002 29.8981 25.5002 28.3333C25.5002 26.7685 24.2316 25.5 22.6668 25.5C21.102 25.5 19.8335 26.7685 19.8335 28.3333C19.8335 29.8981 21.102 31.1667 22.6668 31.1667Z"
      fill="#083317"
    />
    <path
      d="M31.1667 17.7509V19.8333H26.9167C26.1375 19.8333 25.5 19.1958 25.5 18.4167V14.1667C25.5 13.3875 26.1375 12.75 26.9167 12.75H28.7442L30.7983 16.3483C31.0392 16.7733 31.1667 17.255 31.1667 17.7509Z"
      fill="#083317"
    />
  </svg>
</template>

<script>
export default {
  name: "TruckIconColored",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
