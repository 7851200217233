<template>
  <div :class="['form-input-container', inputContainerClass]">
    <label v-if="label" :for="id">
      {{ label }}
    </label>
    <vue-google-autocomplete
      ref="address"
      v-model="address"
      :placeholder="placeholder"
      @placechanged="getAddressData"
      :id="id"
      :class="[
        'form-input-field w-auto',
        errors && errors[name] ? 'form-input-field-invalid' : '',
        inputFieldClass,
      ]"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

function formatAddress(address) {
  return (
    (address.street_number ? `${address.street_number} ` : "") +
    [
      address.route,
      address.locality,
      address.administrative_area_level_1,
      address.country,
    ]
      .filter((part) => part)
      .join(", ")
  );
}

export default {
  name: "GoogleAutocomplete",
  components: { VueGoogleAutocomplete },
  props: {
    id: {
      type: [String],
      default: "address-autocomplete",
    },
    label: {
      type: [String],
      default: "",
    },
    name: {
      type: [String],
      required: true,
    },
    placeholder: {
      type: [String],
      default: "Enter an address",
    },
    errors: {
      type: Object,
      required: false,
    },
    inputContainerClass: {
      type: String,
      default: "",
    },
    inputFieldClass: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    isBilling: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      address: this.modelValue,
    };
  },
  methods: {
    getAddressData(place) {
      const formattedAddress = formatAddress(place);
      this.address = formattedAddress;
      this.$emit("update:modelValue", this.address);
      this.$emit("place-changed", {
        ...place,
        formattedAddress: this.address,
        isBilling: this.isBilling,
      });
    },
  },
  watch: {
    modelValue(newValue) {
      this.address = newValue;
    },
  },
  mounted() {
    if (this.modelValue) {
      this.$refs.address.update(this.modelValue);
      this.address = this.modelValue;
    }
  },
};
</script>
