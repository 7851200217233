<template>
  <div class="main-layout">
    <main-navigation />
    <div class="main-layout__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { MainNavigation } from "@/components";

export default {
  name: "MainLayout",
  components: {
    MainNavigation,
  },
};
</script>
