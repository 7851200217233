<template>
  <svg viewBox="0 0 38 38" :width="size" :height="size" class="close-icon">
    <path
      d="M14.9783 13.8293L24.1707 23.0217C24.4848 23.3357 24.4848 23.8566 24.1707 24.1707C23.8566 24.4848 23.3357 24.4848 23.0217 24.1707L13.8293 14.9783C13.5152 14.6643 13.5152 14.1434 13.8293 13.8293C14.1434 13.5152 14.6643 13.5152 14.9783 13.8293Z"
    />
    <path
      d="M24.1707 13.8293C24.4848 14.1434 24.4848 14.6643 24.1707 14.9783L14.9783 24.1707C14.6643 24.4848 14.1434 24.4848 13.8293 24.1707C13.5152 23.8566 13.5152 23.3357 13.8293 23.0217L23.0217 13.8293C23.3357 13.5152 23.8566 13.5152 24.1707 13.8293Z"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    size: {
      type: [Number, String],
      default: 38,
    },
  },
};
</script>
