<template>
  <div class="w-100">
    <div class="row-between mb-7">
      <p class="f-18-black f-sbold">Leaderboard</p>
      <!-- Do not delete, may be needed in the future -->
      <!-- <time-filter @selectedPeriod="getDataForPeriod" /> -->
    </div>
    <div class="card" v-if="leadersData.length">
      <div class="card__header row-start f-18-black f-medium ph-4">
        <div class="lg-4 pointer" @click="sortData(SORT_BY.COMPANY_NAME)">
          Name
          <sorting-icon
            class="i-darkgrey ml-3"
            :transform="
              sortBy === SORT_BY.COMPANY_NAME && orderBy === ORDER_BY.ASC
                ? '0'
                : '180'
            "
          />
        </div>
        <div class="lg-4 pointer" @click="sortData(SORT_BY.RATE)">
          Rating
          <sorting-icon
            class="i-darkgrey ml-3"
            :transform="
              sortBy === SORT_BY.RATE && orderBy === ORDER_BY.ASC ? '0' : '180'
            "
          />
        </div>
        <div class="lg-4 pointer" @click="sortData(SORT_BY.COMPLETED)">
          Completed
          <sorting-icon
            class="i-darkgrey ml-3"
            :transform="
              sortBy === SORT_BY.COMPLETED && orderBy === ORDER_BY.ASC
                ? '0'
                : '180'
            "
          />
        </div>
      </div>
      <div
        class="card__body row-start f-16-black ph-4 pv-4"
        v-for="item in leadersData"
        :key="item.id"
      >
        <div class="lg-4">{{ item.company_name }}</div>
        <div class="lg-4 pl-20">{{ item.rate }}</div>
        <div class="lg-4 pl-40">{{ item.completed }}</div>
      </div>
    </div>
    <div class="card row-center" v-else>
      <p class="f-18-black">Leaderboard not found</p>
    </div>
  </div>
</template>

<script>
import { SortingIcon } from "@/icons";
// import { TimeFilter } from "@/components";
import { SORT_BY, ORDER_BY } from "@/constants/commonConstants";

export default {
  name: "LeaderCard",
  components: {
    SortingIcon,
    // TimeFilter,
  },
  props: {
    leadersData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["sortData"],
  data: () => {
    return {
      ORDER_BY,
      SORT_BY,
      sortBy: "",
      orderBy: "",
    };
  },
  methods: {
    getDataForPeriod() {},
    sortData(sortBy) {
      if (this.sortBy === sortBy) {
        this.orderBy =
          this.orderBy === this.ORDER_BY.ASC
            ? this.ORDER_BY.DESC
            : this.ORDER_BY.ASC;
      } else {
        this.sortBy = sortBy;
        this.orderBy = this.ORDER_BY.ASC;
      }

      this.$emit("sortData", {
        sort: sortBy,
        order: this.orderBy,
      });
    },
  },
};
</script>
