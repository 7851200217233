import api from "@/api/api.js";

const URLS = {
  profile: "/admin",
  editAdminProfile: "/admin",
  users: "/users",
  blockUser: (id) => `/users/${id}/block`,
  unblockUser: (id) => `/users/${id}/unblock`,
};

export default {
  getProfile(payload) {
    return api.get(URLS.profile, { params: payload });
  },
  editAdminProfile(payload) {
    return api.put(URLS.editAdminProfile, payload);
  },
  getUsersList(payload) {
    return api.get(URLS.users, { params: payload });
  },
  getUserById(payload) {
    return api.get(`${URLS.users}/${payload.userId}`);
  },
  blockUserById(payload) {
    return api.post(URLS.blockUser(payload.userId));
  },
  unblockUserById(payload) {
    return api.post(URLS.unblockUser(payload.userId));
  },
};
