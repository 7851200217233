<template>
  <svg viewBox="0 0 34 34" :width="size" :height="size" class="users-icon">
    <path
      d="M25.4997 11.206C25.4572 11.206 25.4289 11.206 25.3864 11.206H25.3156C22.6381 11.121 20.6406 9.05266 20.6406 6.50266C20.6406 3.89599 22.7656 1.78516 25.3581 1.78516C27.9506 1.78516 30.0756 3.91016 30.0756 6.50266C30.0614 9.06682 28.0639 11.1352 25.5139 11.2202C25.5139 11.206 25.5139 11.206 25.4997 11.206ZM25.3581 3.896C23.9272 3.896 22.7656 5.05767 22.7656 6.4885C22.7656 7.891 23.8564 9.02433 25.2589 9.081C25.2731 9.06683 25.3864 9.06683 25.5139 9.081C26.8881 9.01017 27.9506 7.87683 27.9647 6.4885C27.9647 5.05767 26.8031 3.896 25.3581 3.896Z"
    />
    <path
      d="M25.5142 21.6466C24.9617 21.6466 24.4092 21.6041 23.8567 21.5049C23.2758 21.4058 22.8933 20.8533 22.9925 20.2724C23.0917 19.6916 23.6442 19.3091 24.225 19.4083C25.9675 19.7058 27.8092 19.3799 29.0417 18.5583C29.7075 18.1191 30.0617 17.5666 30.0617 17.0141C30.0617 16.4616 29.6933 15.9233 29.0417 15.4841C27.8092 14.6624 25.9392 14.3366 24.1825 14.6483C23.6017 14.7616 23.0492 14.3649 22.95 13.7841C22.8508 13.2033 23.2333 12.6508 23.8142 12.5516C26.1233 12.1408 28.5175 12.5799 30.2175 13.7133C31.4642 14.5491 32.1867 15.7391 32.1867 17.0141C32.1867 18.2749 31.4783 19.4791 30.2175 20.3291C28.9283 21.1791 27.2567 21.6466 25.5142 21.6466Z"
    />
    <path
      d="M8.45748 11.2055C8.44331 11.2055 8.42915 11.2055 8.42915 11.2055C5.87915 11.1205 3.88164 9.05217 3.86748 6.50217C3.86748 3.8955 5.99248 1.77051 8.58498 1.77051C11.1775 1.77051 13.3025 3.89551 13.3025 6.48801C13.3025 9.05218 11.305 11.1205 8.75498 11.2055L8.45748 10.143L8.55665 11.2055C8.52831 11.2055 8.48581 11.2055 8.45748 11.2055ZM8.59914 9.08051C8.68414 9.08051 8.75498 9.08051 8.83998 9.09467C10.1008 9.03801 11.2058 7.90467 11.2058 6.50217C11.2058 5.07134 10.0441 3.90967 8.61331 3.90967C7.18248 3.90967 6.02081 5.07134 6.02081 6.50217C6.02081 7.8905 7.09748 9.00967 8.47164 9.09467C8.48581 9.08051 8.54248 9.08051 8.59914 9.08051Z"
    />
    <path
      d="M8.44333 21.6466C6.70083 21.6466 5.02916 21.1791 3.73999 20.3291C2.49333 19.4933 1.77083 18.2891 1.77083 17.0141C1.77083 15.7533 2.49333 14.5491 3.73999 13.7133C5.43999 12.5799 7.83416 12.1408 10.1433 12.5516C10.7242 12.6508 11.1067 13.2033 11.0075 13.7841C10.9083 14.3649 10.3558 14.7616 9.775 14.6483C8.01833 14.3366 6.1625 14.6624 4.91583 15.4841C4.25 15.9233 3.89583 16.4616 3.89583 17.0141C3.89583 17.5666 4.26416 18.1191 4.91583 18.5583C6.14833 19.3799 7.98999 19.7058 9.73249 19.4083C10.3133 19.3091 10.8658 19.7058 10.965 20.2724C11.0642 20.8533 10.6817 21.4058 10.1008 21.5049C9.54833 21.6041 8.99583 21.6466 8.44333 21.6466Z"
    />
    <path
      d="M16.9997 21.788C16.9572 21.788 16.9289 21.788 16.8864 21.788H16.8156C14.1381 21.703 12.1406 19.6347 12.1406 17.0847C12.1406 14.478 14.2656 12.3672 16.8581 12.3672C19.4506 12.3672 21.5756 14.4922 21.5756 17.0847C21.5614 19.6489 19.5639 21.7172 17.0139 21.8022C17.0139 21.788 17.0139 21.788 16.9997 21.788ZM16.8581 14.478C15.4272 14.478 14.2656 15.6397 14.2656 17.0705C14.2656 18.473 15.3564 19.6064 16.7589 19.663C16.7731 19.6489 16.8864 19.6489 17.0139 19.663C18.3881 19.5922 19.4506 18.4589 19.4647 17.0705C19.4647 15.6539 18.3031 14.478 16.8581 14.478Z"
    />
    <path
      d="M16.9997 32.2435C15.2997 32.2435 13.5997 31.8044 12.2822 30.9119C11.0355 30.076 10.313 28.886 10.313 27.611C10.313 26.3502 11.0214 25.1318 12.2822 24.296C14.9314 22.5393 19.0822 22.5393 21.7172 24.296C22.9639 25.1318 23.6864 26.3219 23.6864 27.5969C23.6864 28.8577 22.978 30.076 21.7172 30.9119C20.3997 31.7902 18.6997 32.2435 16.9997 32.2435ZM13.458 26.081C12.7922 26.5202 12.438 27.0727 12.438 27.6252C12.438 28.1777 12.8064 28.716 13.458 29.1552C15.3705 30.4444 18.6147 30.4444 20.5272 29.1552C21.193 28.716 21.5472 28.1635 21.5472 27.611C21.5472 27.0585 21.1789 26.5202 20.5272 26.081C18.6289 24.7919 15.3847 24.806 13.458 26.081Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UsersIcon",
  props: {
    size: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
